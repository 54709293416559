import { SecretHash } from "./SecretHash"

function arrayBufferToHex(buffer: ArrayBuffer): string {
    return Array.from(new Uint8Array(buffer))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("")
}

// Generates a random 64-byte secret and its SHA-256 hash.
export async function GenerateSecretAndHash(): Promise<{ secret: string; hash: SecretHash }> {
    const secretBytes = new Uint8Array(32)
    crypto.getRandomValues(secretBytes)
    const secret: string = Array.from(secretBytes)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("")
    const hashBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(secret))
    const hash: string = arrayBufferToHex(hashBuffer)
    return { secret, hash: hash as any as SecretHash }
}
