import { useState } from "react"
import { YTools } from "../../packages/y/YTools"
import { GenerateSecretAndHash } from "../../reactor/Types/Primitives/SecretHash.Client"
import { Property } from "../../reactor/Types/Type"
import { RButton } from "./Buttons"
import { ToolButton } from "./ToolButton"
import { PropRow } from "./PropRow"
import { ColorStyles } from "../../packages/ui"
import { useHover } from "../../packages/hooks/useHover"

export function SecretHashView({
    obj,
    property,
    label,
    buttons,
    isEmbedded,
}: {
    obj: YTools.Node
    property: Property
    label?: string
    buttons: ToolButton[]
    isEmbedded?: boolean
}) {
    const [secret, setSecret] = useState<string | undefined>()
    const [copied, setCopied] = useState(false)
    const [reveal, setReveal] = useState(false)

    async function generate() {
        const { secret: newSecret, hash } = await GenerateSecretAndHash()

        // Copy the secret to the clipboard
        setSecret(newSecret)
        YTools.set(obj, property.name, hash)
    }

    const hash: string = YTools.get(obj, property.name)

    const canGenerate = property.isReadonly ? !hash : false

    return (
        <PropRow
            buttons={[]}
            badge={
                canGenerate ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RButton variant="secondary" icon="ui-dice-5" onClick={generate}>
                            Generate
                        </RButton>
                    </div>
                ) : undefined
            }
            description={property.description}
            label={label}
        >
            {secret ? (
                <div style={{ backgroundColor: ColorStyles.warning[200], padding: 16 }}>
                    Please copy this secret, it is the last time you will see it:
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <RButton icon="ui-eye" onClick={() => setReveal(!reveal)}>
                            {reveal ? "Hide" : "Reveal"}
                        </RButton>
                        <pre
                            style={{
                                padding: 16,
                                filter: reveal ? undefined : `blur(10px)`,
                                transition: "filter 0.3s ease",
                            }}
                        >
                            {secret}
                        </pre>
                    </div>
                    <RButton
                        variant="secondary"
                        icon={copied ? "ui-check" : "ui-clipboard"}
                        style={{ marginTop: 0, marginBottom: 16 }}
                        onClick={() => {
                            navigator.clipboard.writeText(secret || "")
                            setCopied(true)
                            setTimeout(() => setCopied(false), 2000)
                        }}
                    >
                        {!copied ? "Copy to clipboard" : "Copied!"}
                    </RButton>
                </div>
            ) : undefined}
            {hash ? (
                <input
                    type="text"
                    className="form-control"
                    value={hash.substring(0, 30) + "..."}
                    readOnly
                />
            ) : undefined}
        </PropRow>
    )
}
